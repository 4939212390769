<template>
  <div class="columns" ref="card">
    <div class="columns is-mobile is-full-mobile is-centered is-vcentered">
      <div class="card-news" id="div-news">
        <p
          :style="research === '' && win > 1024 ? 'width: 50vh' : null"
          class="text-center"
          id="p-news"
        >
          {{
            research !== ""
              ? `Clique no botão a seguir e responda:  ${research}`
              : "Clique no botão e assine nossa newsletter"
          }}
        </p>
        <div id="btn-wrapper">
          <div
            id="btn-pesquisa"
            class="
              column
              is-centered
              is-full-mobile
              is-full-desktop
              is-full-widescreen
              is-justify-content-center
            "
          >
            <button
              :style="research === '' ? 'visibility: hidden' : null"
              id="pesquisa"
              class="btn btn-gray-solid text-uppercase btn-news-mobile"
              @click="answerResearch()"
            >
              responder pesquisa
            </button>
          </div>
          <div
            id="btn-newsletter"
            class="
              column
              is-centered
              is-full-mobile
              is-full-desktop
              is-full-widescreen
              is-justify-content-center
            "
          >
            <button
              class="btn btn-orange-solid text-uppercase btn-news-mobile"
              @click="subscribeNews()"
            >
              assinar <em>newsletter</em>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subscribeNews from "../../../mixins/subscribeNews";
import answerResearch from "../../../mixins/answerResearch";
export default {
  data() {
    return {
      win: window.innerWidth
    };
  },
  mixins: [subscribeNews, answerResearch],
  props: {
    hasResearch: {
      type: Boolean,
      required: true,
      default: false
    },
    research: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
@import url("./index.css");
</style>
