<template>
  <div :class="classCard()">
    <div class="gestao card-impacto">
      <span :class="titleImage"></span>
      <h3 class="gestao-titulo-artigos">
        <i :class="icon"></i> {{ title.toUpperCase() }}
      </h3>
      <p class="gestao-descricao-artigos">
        {{ desc }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["titleImage", "title", "mais", "icon", "desc", "colMd"],
  methods: {
    classCard() {
      var $class = "col-sm-12 slick-slide slick-cloned ";
      $class =
        $class +
        (this.colMd
          ? "col-md-6 col-lg-4 col-xl-" + this.colMd
          : " col-md-6 col-lg-6 col-xl-4");
      return $class;
    }
  }
};
</script>
<style src="./index.css"></style>
