const asnwer = {
  data() {
    return {
      inf: this.$store.getters.cards,
      link: ""
    };
  },
  methods: {
    answerResearch() {
      let r = this.$store.getters.cards.map(c => c.route);
      for (let routes = 0; routes < r.length; routes++) {
        if (r[routes] === this.$route.name) {
          this.link = this.inf[routes].link;
          console.log(this.link);
          window.open(this.link);
        }
      }
    }
  }
};

export default asnwer;
