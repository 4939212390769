<template>
  <div>
    <div class="main-container">
      <main role="main" style="overflow: hidden">
        <BannerArtigo imgBack="imp-ambiental-artigo" />

        <div class="section section-white section-top">
          <div class="container">
            <div class="row">
              <!-- começa o primeiro parágrafo deveria ser title outline-->
              <div class="col-md-6">
                <h1 class="title1">
                  <i style="float: left" :class="imgIcon"></i
                  ><span class="span-relevance-artigo">{{ relevance }}</span>
                  <span>
                    <b style="color: var(--cor-primaria)">{{ title }}</b>
                    {{ subtitle }}</span
                  >
                </h1>
                <span>
                  <b style="color: var(--cor-primaria)">Palavras-chave: </b
                  ><span>{{ keywords }}</span>
                </span>
                <p class="paragraph">
                  <span>{{ author }} {{ datePub }} <br />{{ timePub }}</span>
                </p>
                <div class="paragraph">
                  <p>{{ desc }}</p>
                </div>
              </div>

              <!-- relacionados -->
              <div class="col-md-6">
                <!-- <CardNews :research="research" /> -->
                <Newsletter :hasResearch="false" :research="research" />
                <div id="fixed-roll">
                  <div class="slick-slider slick-cloned">
                    <h2 class="artigo-h2">
                      <span class="title1 title-artigo">
                        Artigos relacionados
                      </span>
                    </h2>
                    <div class="row">
                      <div
                        class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                        v-for="(card, index) in cards"
                        :key="index"
                      >
                        <CardRelacionado
                          :title="card.title"
                          :subtitle="card.subtitle"
                          :keywords="card.keywords"
                          :relevance="card.relevance"
                          :imgIcon="card.imgIcon"
                          :route="card.route"
                        />
                      </div>
                    </div>
                    <!--   </div> -->

                    <!-- <div class="slick-slider slick-cloned "> -->
                    <h2 class="artigo-h2 artigo-latest-h2">
                      <span class="title1 title-artigo title-artigo-center">
                        últimos artigos
                      </span>
                    </h2>
                    <div class="row">
                      <div
                        class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                        v-for="(card, index) in latest"
                        :key="index"
                      >
                        <CardRelacionado
                          :title="card.title"
                          :subtitle="card.subtitle"
                          :keywords="card.keywords"
                          :relevance="card.relevance"
                          :imgIcon="card.imgIcon"
                          :route="card.route"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- termina relacionados -->
            </div>
          </div>
          <!-- fim do container top -->
        </div>
        <!-- fim da section top -->

        <!-- começa seção do artigo -->
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <!--col-sm-8 col-md-6-->
                  <div class="info">
                    <h2 id="fix">
                      <strong>
                        O desafio de empreender com escassez de recursos
                      </strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        src="../../assets/images/grafico.png"
                        alt="Imagem de um gráfico com uma caneta em cima"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      Sem minimizar o desafio, é certo que o Brasil oferece
                      tantas oportunidades de investimento que, com as condições
                      de mercado certas, é candidato a ser o destino
                      preferencial de investidores que incorporem fatores de
                      riscos ambientais, sociais e de governança (ASG) em suas
                      análises e processos decisórios.
                    </p>
                    <p>
                      A emissão de títulos verdes, por exemplo, pode oferecer
                      soluções para financiar esforços em larga escala de
                      adaptação às mudanças climáticas, ao mesmo tempo gerando
                      emprego, renda e reflexos positivos para toda a sociedade.
                    </p>
                    <p>
                      Mas o pleno aproveitamento dessas oportunidades requer a
                      capacidade de incorporar considerações de sustentabilidade
                      na agenda regulatória. De um lado, novas classes de ativos
                      impõe reexame da adequação do quadro normativo às
                      especificidades dos instrumentos. De outra parte,
                      investidores que incorporaram fatores ASG em suas análises
                      possuem necessidades informacionais específicas que
                      precisam de solução, mesmo que por padrões voluntários,
                      como os propostos pela “Task Force on Climate-related
                      Financial Disclosures” instituída pelo FSB.
                    </p>
                    <p>
                      Trata-se de uma questão estratégica, por isso iremos ver
                      um pouco mais adiante algumas das táticas e estratégias
                      com impacto direto na regeneração do meio ambiente que
                      você pode seguir.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>Como medir a regeneração da sua empresa</strong>
                    </h2>

                    <p>
                      O planejamento de como atuar de forma regenerativa para
                      uma empresa não é uma tarefa simples, e que cabe a grandes
                      empresas ou associações uma maior atenção, porém isso não
                      exclui startups que queiram atuar no setor e vender
                      créditos para pequenas empresas que enxergam o impacto no
                      meio ambiente uma oportunidade de negócios.
                    </p>
                    <p>
                      O fluxograma a seguir resume de forma visual os objetivos
                      que a empresa deve ter na primeira coluna, os resultados e
                      diagnósticos na segunda colunas, e o estado do
                      planejamento na terceira coluna:
                    </p>

                    <br />
                    <figure class="figure figure-artigo figure-ciclo">
                      <img
                        id="crescimento"
                        src="../../assets/images/Planejamento de Regeneração.png"
                        alt="Infográfico reprsentando o planejamento de regeneração"
                        class="img-responsive"
                      />
                      <figcaption
                        style="
                          font-size: 12px;
                          text-align: center;
                          margin: auto;
                        "
                      >
                        Infográfico reprsentando o planejamento de regeneração
                      </figcaption>
                    </figure>

                    <p>
                      É claro que o apoio da tecnologia seja através de
                      processamento de imagens via satélite, ou inventário de
                      biomassa podem apoiar soluções que monitorem regiões de
                      revitalização florestal. E sensores de qualidade da água
                      podem auxiliar na medição de nascentes, rios e lagos.
                    </p>
                    <p>
                      A shinier tem empresas parceiras que dominam essas
                      tecnologia e que podem estar auxiliando na sua solução,
                      juntos vamos entregar as soluções ao seu negócio e fazer
                      nossa parte no caminho para um planeta melhor. Entre em
                      contato com
                      <a href="mailto:contato@shinier.com.br"
                        >contato@shinier.com.br</a
                      >, ou WhatsApp
                      <a href="https://wa.me/+5516981354817">+5516981354817</a>
                      (Marcius) para agendar uma consultoria.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong
                        >Reavaliar as atividades da empresa de forma sustentavel
                      </strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        src="../../assets/images/Reavaliar as atividades da empresa de forma sustentavel.png"
                        alt="Imagem de uma mão segurando um punhado de terra"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      Para caminhar em direção à responsabilidade
                      socioambiental, é preciso analisar o funcionamento da
                      empresa e encontrar focos de melhoria. Para isso, você
                      pode conversar com os responsáveis por cada setor ou rever
                      os processos planejados para cada um deles. Assim, as
                      tarefas de rotina são avaliadas e repensadas.
                    </p>
                    <p>
                      Contabilize o desperdício que a sua organização produz,
                      seja em material de escritório, copos descartáveis ou
                      qualquer outro. Pense em medidas de redução de
                      desperdício, como distribuir canecas personalizadas,
                      diminuir o consumo de energia elétrica, de água ou reduzir
                      o uso de papel, por exemplo.
                    </p>
                    <p>
                      Considere a origem dos insumos usados na sua empresa,
                      dando prioridade aos fornecedores que também têm
                      iniciativas sustentáveis. Reduzir o uso de matéria prima
                      animal ou de produtos que são testados em animais é outra
                      forma de diminuir o impacto ambiental sem alterar nenhum
                      processo interno. A reciclagem também é de fácil adoção e
                      impacto positivo na natureza.
                    </p>
                    <p>
                      Reflita ainda sobre o impacto da empresa, seja em termos
                      econômicos ou ambientais. As pessoas que moram ou
                      trabalham no seu entorno podem se beneficiar das suas
                      atividades de alguma forma? Se você estiver com
                      dificuldade para visualizar uma forma de impactar
                      positivamente a comunidade, converse com uma ONG ambiental
                      da sua região em busca de parceria.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong
                        >Substituir ou reduzir insumos para ter impacto positivo
                      </strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        src="../../assets/images/Substituir ou reduzir insumos para ter impacto positivo.png"
                        alt="Substituir ou reduzir insumos para ter impacto positivo"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      No Brasil e em outros países há a necessidade de haver uma
                      adequação das empresas ao meio ambiente. Um modo de fazer
                      isso é apostar na compra sustentável. Essa é uma atitude
                      que evita um alto impacto ao meio ambiente. Ao mesmo tempo
                      cumpre o que está determinado na Lei 9.795/1999, que
                      aborda questões de educação ambiental e implementa a
                      Política Nacional de Educação Ambiental. Mas qual é o
                      conceito desse tipo de compra e quais são os benefícios
                      que sua empresa pode obter?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <div class="paragraph">
                      <!--  <div class="info"> -->
                      <h3 class="title-h2 title-outline">
                        <b>O que é compra sustentável?</b>
                      </h3>
                      <p>
                        Essa modalidade é aquela em que se prevê o uso eficiente
                        dos recursos materiais para que seu impacto ambiental
                        seja reduzido. A ideia é integrar elementos ambientais
                        em todas as etapas do processo de compra e evitar as
                        aquisições desnecessárias. Também é possível substituir
                        os insumos por elementos mais sustentáveis, que estejam
                        de acordo com as especificações de uso. Ou seja, os
                        aspectos positivos ao meio ambiente devem estar aliados
                        às necessidades da sua empresa.
                      </p>
                      <p>
                        De modo geral, os produtos sustentáveis funcionam ou são
                        fabricados de acordo com as seguintes características:
                      </p>
                      <p></p>
                      <ul
                        style="
                          list-style: inside;
                          list-style-type: disc;
                          text-align: justify;
                        "
                      >
                        <li>menor uso de recursos naturais;</li>
                        <li>redução de materiais tóxicos ou perigosos;</li>
                        <li>maior vida útil;</li>
                        <li>
                          menor consumo de energia ou água em sua produção e/ou
                          utilização;
                        </li>
                        <li>possibilidade de reciclagem ou reutilização;</li>
                        <li>menor geração de resíduos.</li>
                      </ul>

                      <p></p>
                      <p>
                        É importante destacar que o produto sustentável não é
                        somente aquele que é reciclado. Ele pode ter outras
                        funcionalidades ou propósitos, como mobílias, transporte
                        e construção. A boa notícia é que há cada vez mais
                        fornecedores que atendem a esses requisitos porque a
                        demanda também está maior.
                      </p>
                      <!-- </div> -->
                    </div>

                    <div class="paragraph">
                      <!--  <div class="info"> -->
                      <h3 class="title-h2 title-outline">
                        <b>Quais são os benefícios?</b>
                      </h3>
                      <p>
                        As compras sustentáveis beneficiam o comprador, o
                        mercado e a comunidade em que sua empresa está inserida.
                      </p>
                      <p><b>No primeiro caso, as vantagens são:</b></p>
                      <p></p>
                      <ul
                        style="
                          list-style: inside;
                          list-style-type: disc;
                          text-align: justify;
                        "
                      >
                        <li>
                          melhor custo-benefício e maior eficiência no uso dos
                          recursos;
                        </li>
                        <li>
                          maior geração de economia devido à eficiência
                          energética, diminuição do uso de água, eliminação de
                          resíduos e reuso de produtos e materiais. Isso diminui
                          o custo da mercadoria durante todo seu ciclo de vida;
                        </li>
                        <li>
                          maior publicidade positiva relativa à compra e
                          utilização de serviços, produtos e fornecedores que
                          têm boa reputação social e ambiental;
                        </li>
                        <li>
                          mais liderança na comunidade pela adoção dessa
                          atitude;
                        </li>
                        <li>
                          maior capacidade de elaborar práticas socialmente
                          conscientes e inovadoras
                        </li>
                      </ul>

                      <p>
                        <b
                          >Para o mercado, os benefícios que podem ser
                          conquistados são:</b
                        >
                      </p>
                      <ul
                        style="
                          list-style: inside;
                          list-style-type: disc;
                          text-align: justify;
                        "
                      >
                        <li>
                          mais disponibilidade de serviços e produtos
                          sustentáveis por preços mais rentáveis;
                        </li>
                        <li>
                          possibilidade de ampliação do mercado para serviços e
                          produtos sustentáveis, podendo beneficiar empresas
                          locais;
                        </li>
                        <li>
                          ampliação das oportunidades de mercado pela
                          diferenciação de produtos e serviços;
                        </li>
                        <li>
                          diminuição dos custos com transporte, por exemplo:
                          combustível, congestionamentos rodoviários e
                          manutenção de veículos;
                        </li>
                        <li>
                          mais apoio e incentivo à inovação, porque os produtos
                          preferidos são aqueles mais sustentáveis;
                        </li>
                        <li>
                          mais incentivo à indústria para desenvolver a
                          capacidade de trabalhar a partir dos preceitos de uma
                          economia verde e limpa.
                        </li>
                      </ul>

                      <p></p>

                      <p>
                        <b
                          >Já para a comunidade, as vantagens que podem ser
                          destacadas são:
                        </b>
                      </p>
                      <p></p>
                      <ul
                        style="
                          list-style: inside;
                          list-style-type: disc;
                          text-align: justify;
                        "
                      >
                        <li>
                          diminuição dos impactos ao meio ambiente e sociais
                          devido às opções de compra;
                        </li>
                        <li>
                          diminuição da quantidade de resíduos para os aterros,
                          além da economia de água e menor emissão de gases de
                          efeito estufa;
                        </li>
                        <li>redução da poluição da água e do ar;</li>
                        <li>
                          redução do consumo de recursos processados e naturais;
                        </li>
                        <li>promoção da segurança, saúde e igualdade;</li>
                        <li>
                          influência às decisões de aquisição para apoiar
                          aspectos de diversidade e igualdade;
                        </li>
                        <li>
                          promoção do desenvolvimento de infraestrutura física e
                          comunidades locais;
                        </li>
                        <li>
                          fortalece a coesão e a inclusão social pela criação de
                          oportunidades de negócio e emprego para grupos
                          marginalizados ou desfavorecidos.
                        </li>
                      </ul>
                      <p></p>
                      <!--   </div> -->
                    </div>

                    <div class="paragraph">
                      <!--  <div class="info"> -->
                      <h3 class="title-h2 title-outline">
                        <b>Como fazer compras sustentáveis?</b>
                      </h3>
                      <figure
                        class="figure figure-artigo"
                        style="float: right; margin-left: 60px"
                      >
                        <img
                          src="../../assets/images/Compra sustentável.png"
                          alt="Compra sustentável"
                          class="img-responsive"
                        />
                      </figure>
                      <p>
                        Essas aquisições podem ser realizadas de diversas
                        maneiras, mas é importante seguir alguns aspectos que
                        fazem toda a diferença.
                      </p>
                      <p>Veja quais são eles:</p>
                      <p></p>
                      <ul
                        style="
                          list-style: inside;
                          list-style-type: disc;
                          text-align: justify;
                        "
                      >
                        <li>
                          exija que a legislação seja cumprida e verifique a
                          regulamentação sobre a procura de serviços e produtos;
                        </li>
                        <li>
                          estimule os fornecedores a oferecerem produtos e
                          serviços responsáveis no âmbito ambiental e com preços
                          competitivos;
                        </li>
                        <li>
                          incentive os parceiros a fazerem a reciclagem e coleta
                          de produtos usados e analisarem os impactos dos
                          serviços de entrega;
                        </li>
                        <li>
                          informe-se sobre a performance ambiental de produtos.
                          Tenha em mente que as decisões de compra devem estar
                          subsidiadas em informações precisas e confiáveis a
                          respeito do ciclo de vida de cada item;
                        </li>
                        <li>
                          determine a disponibilidade dos fornecedores de
                          produtos adequados aos aspectos ambientais. Esse é um
                          fator crítico para fazer as compras sustentáveis.
                          Portanto, faça o levantamento de fornecedores e
                          produtos, analise a política dos parceiros e
                          estimule-os a disponibilizarem alternativas
                          ambientalmente positivas.
                        </li>
                      </ul>

                      <p>
                        Resumindo: a compra de produtos e serviços sustentáveis
                        garante uma melhor reputação para a sua empresa, maior
                        poder competitivo e a conquista de uma boa fatia de
                        mercado.
                      </p>

                      <p>
                        Agora que você já sabe da importância da compra
                        sustentável, que tal aplicar essa ideia na sua empresa?
                        Entre em contato com
                        <a href="mailto:contato@shinier.com.br"
                          >contato@shinier.com.br</a
                        >
                        , ou WhatsApp
                        <a href="https://wa.me/+5516981354817"
                          >+5516981354817</a
                        >
                        (Marcius) para agendar uma consultoria.
                      </p>

                      <!--   </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong
                        >Sustentabilidade não é suficiente. Agora é a hora da
                        regeneração!
                      </strong>
                    </h2>
                    <p>
                      Pensar na regeneração do planeta pode ser paralisante e
                      até desesperador. Por isso mesmo, o modelo regenerativo
                      propõe um olhar local, contemplando as especificidades de
                      cada lugar. O olhar regenerativo exige que cada empresa
                      estude profundamente seu entorno para analisar cada tipo
                      de relação e necessidade que a comunidade tem.
                    </p>
                    <p>
                      O impacto da regeneração dos ecossistemas na agenda
                      climática é inegável. De acordo com o World Resources
                      Institute:
                    </p>
                    <p></p>
                    <ul
                      style="
                        list-style: inside;
                        list-style-type: disc;
                        text-align: justify;
                      "
                    >
                      <li>
                        Se a natureza fosse protegida e restaurada em larga
                        escala, poderia garantir mais de um terço da redução
                        anual de emissões que o mundo precisa para manter o
                        aumento da temperatura abaixo de 2°C;
                      </li>
                      <li>
                        As florestas sozinhas armazenam 1,5 vez mais carbono do
                        que o emitido pelos Estados Unidos anualmente. Além
                        disso, poderiam absorver 23% das emissões globais de CO²
                        todos os anos se as deixássemos regenerar de forma
                        natural.
                      </li>
                      <li>
                        Restaurar os ecossistemas pode atender diretamente a
                        comunidades e povos indígenas, muitos dos quais dependem
                        dos recursos naturais para manter seus meios de
                        subsistência e práticas culturais.
                      </li>
                    </ul>
                    <p></p>
                    <p>
                      A agenda da regeneração também tem o potencial de ser
                      economicamente muito vantajosa. De acordo com um estudo do
                      Programa das Nações Unidas para o Meio Ambiente, em escala
                      global, cada US$ 1 investido na restauração de paisagens
                      degradadas pode render entre US$ 7 e US$ 30.
                    </p>
                    <p>
                      Isso sem falar que a regeneração de ecossistemas traz
                      oportunidades de geração de emprego e renda. Só nos
                      Estados Unidos, essa é uma indústria de US$ 25 bilhões que
                      emprega 220 mil pessoas, mais do que a de carvão, aço ou
                      de extração de madeira.
                    </p>
                    <p>
                      Um exemplo desse potencial pode ser visto nos recifes de
                      coral. Apesar de cobrirem menos de 0,1% do oceano mundial,
                      eles sustentam mais de 25% da biodiversidade marinha. Além
                      disso, servem pelo menos um bilhão de pessoas com uma
                      ampla gama de serviços ecossistêmicos – como, por exemplo:
                      proteção costeira, produção pesqueira, fontes de
                      medicamentos, benefícios recreativos e rendimento
                      turístico.
                    </p>
                    <p>
                      Tem uma ideia de negócio para sua empresa, ou uma startup,
                      conte com a shinier para te dar apoio tecnológico. Entre
                      em contato com
                      <a href="mailto:contato@shinier.com.br"
                        >contato@shinier.com.br</a
                      >, ou WhatsApp
                      <a href="https://wa.me/+5516981354817">+5516981354817</a>
                      (Marcius) para agendar uma consultoria.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção do artigo -->
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>

        <!-- referencias -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info" id="refs">
                  <h2 class="title1" style="text-align: justify">
                    Referências
                  </h2>
                  <p></p>
                  <ul
                    class="references list-top"
                    style="text-align: justify; list-style: inside"
                  >
                    <li>
                      KISIL, M.; FABIANI, P.M.J. Retorno Social do Investimento
                      (SROI): Metodologia que traduz o impacto social para o
                      investidor. Pensamento e Realidade, v3. 2016.
                    </li>
                    <li>
                      MONTIBELLER FILHO, G. O mito do desenvolvimento
                      Sustentável: meio ambiente e custos sociais no moderno
                      sistema produtor de mercadorias. Florianópolis: Ed. Da
                      UFCS, 2004.
                    </li>
                    <li>
                      NASSIF, V. M. J.; et al. Afinal, quem é o Líder voltado
                      aos Princípios do Desenvolvimento Sustentável? ENCONTRO
                      NACIONAL DOS PROGRAMAS DE PÓS-GRADUAÇÃO EM ADMINISTRAÇÃO,
                      XXVIII., 2004, Curitiba. Anais…
                    </li>
                    <li>
                      ROMEIRO, A. R. Economia ou economia política da
                      sustentabilidade. In: MAY, P. H.; LUSTOSA, M. C.; VINHA,
                      V. Economia do meio ambiente. Rio de Janeiro: Elsevier:
                      2003. p. 1-29.
                    </li>
                  </ul>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- fim das referências -->
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import BannerArtigo from "../../components/Blog/BannerArtigo";
import CardRelacionado from "../../components/Blog/CardRelacionado";
/* import CardNews from "../../components/Blog/CardNews"; */
import Newsletter from "../../components/Blog/Newsletter/index.vue";
import showCards from "@/mixins/showCards";
import scrollToTop from "@/mixins/scrollToTop";
import Scroll from "../../mixins/transformToScroll";
export default {
  name: "Artigo01",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Empreendedorismo e meio ambiente",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content: "Empreendedorismo e meio ambiente | Shinier"
      },
      {
        name: "description",
        content:
          "O desafio de promover o desenvolvimento sustentável requer uma ação coletiva e intersetorial para diagnóstico de entraves e construção de um planejamento adequado."
      },
      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "Empreendedorismo e meio ambiente | Shinier"
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/aceleracao/empreendedorismo-e-meio-ambiente"
      },
      {
        property: "og:description",
        content:
          "O desafio de promover o desenvolvimento sustentável requer uma ação coletiva e intersetorial para diagnóstico de entraves e construção de um planejamento adequado."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/imp-ambiental.62376289.png"
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/imp-ambiental.62376289.png"
      },
      {
        name: "twitter:description",
        content:
          "O desafio de promover o desenvolvimento sustentável requer uma ação coletiva e intersetorial para diagnóstico de entraves e construção de um planejamento adequado."
      },
      { property: "og:site_name", content: "Shinier | Aceleração" }
    ]
  },
  mixins: [showCards, scrollToTop, Scroll],
  components: {
    BannerArtigo: BannerArtigo,
    CardRelacionado: CardRelacionado,
    /*     CardNews: CardNews, */
    Newsletter: Newsletter,
    GetFeedback: GetFeedback
  }
};
</script>
<style>
@import url("../Aceleracao/index.css");
</style>
