<template>
  <div id="page-banner-artigos" :class="imgBack"></div>
</template>
<script>
export default {
  props: ["imgBack"]
};
</script>
<style scoped>
@import url("./index.css");
</style>
