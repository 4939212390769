const transformToScroll = {
  data() {
    return {
      isMobile:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      width: 1024
    };
  },
  created() {
    this.isMobile > this.width
      ? window.addEventListener("scroll", this.Scroll)
      : window.removeEventListener("scroll", this.Scroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.Scroll);
  },
  methods: {
    Scroll() {
      var div = document.getElementById("div-news");
      var wrapper = document.getElementById("btn-wrapper");

      var scroll = window.pageYOffset;
      let fixed = document.getElementById("fix");
      if (scroll > fixed.offsetTop) {
        div.classList.add("div-news-header");
        wrapper.classList.add("displaybtn");
        this.addBtnCol();
        //se ele for menor que a altura onde está
        //o parágrafo, ele remove o header
      } else if (scroll <= fixed.offsetTop) {
        div.classList.remove("div-news-header");
        wrapper.classList.remove("displaybtn");
        this.removeBtnCol();
      }
    },
    addBtnCol() {
      var btn1 = document.getElementById("btn-pesquisa"),
        btn2 = document.getElementById("btn-newsletter");
      btn1.classList.remove("is-full-desktop");
      btn1.classList.remove("is-full-widescreen");
      btn2.classList.remove("is-full-desktop");
      btn2.classList.remove("is-full-widescreen");

      btn1.classList.add("is-8-desktop");
      btn1.classList.add("is-8-widescreen");
      btn2.classList.add("is-8-desktop");
      btn2.classList.add("is-8-widescreen");

    },
    removeBtnCol() {
      var btn1 = document.getElementById("btn-pesquisa"),
        btn2 = document.getElementById("btn-newsletter");
      btn1.classList.add("is-full-desktop");
      btn1.classList.add("is-full-widescreen");
      btn2.classList.add("is-full-desktop");
      btn2.classList.add("is-full-widescreen");

      btn1.classList.remove("is-8-desktop");
      btn1.classList.remove("is-8-widescreen");
      btn2.classList.remove("is-8-desktop");
      btn2.classList.remove("is-8-widescreen");
    }
  }
};

export default transformToScroll;
