<template>
  <nav class="nav-filter">
    <div class="columns is-mobile is-vcentered is-centered">
      <div class="column" v-if="win == true">
        <p class="column is-narrow filter-header" v-if="win == true">
          Não deixe de nos auxiliar respondendo os formulários e pesquisas que
          surgem nos pop-ups ao fim de cada artigo.
        </p>
      </div>
      <div
        class="column is-narrow is-5-desktop is-mobile is-vcentered filter-menu"
      >
        <b-dropdown class="filters">
          <template #trigger>
            <i class="filter-icon"></i>
            <b-button
              style="
                border-right: solid 2px antiquewhite !important;
                border-radius: 0;
                padding-left: 1em;
              "
              label="Filtros"
              type="is-info"
              class="filter-switch switch-btn"
            />
          </template>

          <b-dropdown-item class="filter-title"
            ><span class="filter-title"
              ><i class="flag-menu imp-color-red"></i>Impacto econômico</span
            >
            <label for="ec" class="switch">
              <input id="ec" type="checkbox" @change="selectCategory('ec')" />
              <span class="slider round"></span>
            </label>
          </b-dropdown-item>

          <b-dropdown-item class="filter-title"
            ><span class="filter-title"
              ><i class="flag-menu imp-color-green"></i>Impacto ambiental</span
            >
            <label for="a" class="switch">
              <input id="a" type="checkbox" @change="selectCategory('a')" />
              <span class="slider round"></span>
            </label>
          </b-dropdown-item>

          <b-dropdown-item class="filter-title"
            ><span class="filter-title"
              ><i class="flag-menu imp-color-blue"></i>Impacto social</span
            >
            <label for="s" class="switch">
              <input id="s" type="checkbox" @change="selectCategory('s')" />
              <span class="slider round"></span>
            </label>
          </b-dropdown-item>
        </b-dropdown>
        <!-- <span class="pipe-separate">|</span> -->
        <b-dropdown class="filters">
          <template #trigger>
            <b-button
              label="Ordernar por"
              type="is-info"
              class="filter-switch switch-btn"
            />
          </template>

          <b-dropdown-item style="font-size: 12px"
            >Data
            <label class="switch ord1">
              <input id="date" type="checkbox" @click="selectOrder('date')" />
              <span class="slider round"></span>
            </label>
          </b-dropdown-item>
          <b-dropdown-item style="font-size: 12px"
            >A - Z
            <label class="switch ord1">
              <input id="alpha" type="checkbox" @click="selectOrder('alpha')" />
              <span class="slider round"></span>
            </label>
          </b-dropdown-item>
          <b-dropdown-item style="font-size: 12px"
            >Relevância
            <label class="switch ord1">
              <input id="relev" type="checkbox" @click="selectOrder('relev')" />
              <span class="slider round"></span>
            </label>
          </b-dropdown-item>
        </b-dropdown>

        <button
          class="btn-orange-solid btn-news"
          v-if="win == true"
          @click="subscribeNews()"
        >
          <span>ASSINAR <em>NEWSLETTER</em> </span>
        </button>
      </div>
      <!-- btn assinar news -->
    </div>
  </nav>
</template>

<script>
import cards from "../../../store/modules/cards";
import subscribeNews from "../../../mixins/subscribeNews";
import $ from "jquery";
export default {
  data() {
    return {
      win: true,
      cards: this.$store.getters.cards
    };
  },
  mixins: [subscribeNews],
  created() {
    /*ativa o template de telas mobile*/
    if (window.innerWidth < 1025) {
      this.win = false;
    }
  },
  methods: {
    /*seleção de categorias (impacto ambiental, economico, etc..)*/
    selectCategory(id) {
      var id_ = document.getElementById(id);
      const red = "imp-color-red";
      const green = "imp-color-green";
      const blue = "imp-color-blue";
      var ec = document.getElementById("ec");
      var a = document.getElementById("a");
      var s = document.getElementById("s");

      /*       var container = document.getElementById("card-container"); */

      const filter_economico = cards.state.cards.filter(c => c.imgIcon === red);
      const filter_ambiental = cards.state.cards.filter(
        c => c.imgIcon === green
      );

      const filter_social = cards.state.cards.filter(c => c.imgIcon === blue);

      if ($(id_).is(":checked") && id_.id === "ec") {
        let a = document.getElementById("a");
        let s = document.getElementById("s");
        a.checked = false;
        s.checked = false;
        if (filter_economico.length === 0) {
          this.cards = [{}];
          return this.$store.dispatch(
            "filtering_category",
            this.cards,
            "Não encontrado"
          );
        }
        this.cards = filter_economico;
        return this.$store.dispatch("filtering_category", this.cards);
      } else if ($(id_).is(":checked") && id_.id === "a") {
        let ec = document.getElementById("ec");
        let s = document.getElementById("s");
        ec.checked = false;
        s.checked = false;
        if (filter_ambiental.length === 0) {
          this.cards = [{}];
          return this.$store.dispatch(
            "filtering_category",
            this.cards,
            "Não encontrado"
          );
        }

        this.cards = filter_ambiental;
        return this.$store.dispatch("filtering_category", this.cards);
      } else if ($(id_).is(":checked") && id_.id === "s") {
        let ec = document.getElementById("ec");
        let a = document.getElementById("a");
        ec.checked = false;
        a.checked = false;
        if (filter_social.length === 0) {
          this.cards = [{}];
          return this.$store.dispatch(
            "filtering_category",
            this.cards,
            "Não encontrado"
          );
        }
        this.cards = filter_social;
        return this.$store.dispatch("filtering_category", this.cards);
      } else if (
        ec.checked === false &&
        a.checked === false &&
        s.checked === false
      ) {
        return (
          (this.cards = cards.state.cards),
          this.$store.dispatch("show_cards", this.cards)
        );
      }
    },

    /*seleção de ordem (ordem A-Z, data, etc..)*/
    selectOrder(id) {
      var id_ = document.getElementById(id);
      var d = document.getElementById("date");
      var a = document.getElementById("alpha");
      var r = document.getElementById("relev");

      if ($(id_).is(":checked") && id_.id === "date") {
        a.checked = false;
        r.checked = false;
        let sort = this.cards.sort(function(a, b) {
          if (a.datePub < b.datePub) {
            return 1;
          }
          if (a.datePub > b.datePub) {
            return -1;
          }

          return 0;
        });

        return this.$store.dispatch("filtering_date", sort);
      } else if ($(id_).is(":checked") && id_.id === "alpha") {
        d.checked = false;
        r.checked = false;
        let order = this.cards.sort(function(a, b) {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }

          return 0;
        });

        return this.$store.dispatch("filtering_alpha", order);
      } else if ($(id_).is(":checked") && id_.id === "relev") {
        a.checked = false;
        d.checked = false;
        let relevance = this.cards.sort(function(a, b) {
          if (a.relevance > b.relevance) {
            return 1;
          }
          if (a.relevance < b.relevance) {
            return -1;
          }

          return 0;
        });

        return this.$store.dispatch("filtering_relevance", relevance);
      } else if (
        d.checked === false &&
        a.checked === false &&
        r.checked === false
      ) {
        return (
          (this.cards = cards.state.cards),
          this.$store.dispatch("show_cards", this.cards)
        );
      }
    }
  }
};
</script>
<style>
@import url("./index.css");
</style>
