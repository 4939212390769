<template>
  <a @click="$router.push({ name: route })">
    <div class="all-soluction container bg-white" style="overflow: hidden">
      <div class="ribbon ribbon-top-right" :style="'border-color:' + imgIcon">
        <span>{{ relevance }}</span>
      </div>
      <img :class="'artigo-card-img ' + imgCard" />
      <h3 style="font-size: 20px">{{ title }}{{ subtitle }}</h3>
      <p style="font-size: 14px">
        <br />
        {{ desc }}<a style="float: right"> &nbsp; Leia mais...</a><br />
      </p>

      <p style="float: right; font-size: 14px">
        <span>Palavras-chave: </span> {{ keywords }}
      </p>

      <p style="float: right; font-size: 14px">{{ author }} {{ datePub }}</p>
    </div>
  </a>
</template>
<script>
export default {
  props: [
    "relevance",
    "imgIcon",
    "imgCard",
    "title",
    "subtitle",
    "desc",
    "route",
    "keywords",
    "author",
    "datePub",
    "timePub",
  ],
};
</script>
<style src="./index.css" scoped>
/* @import url("./index.css"); */
</style>
