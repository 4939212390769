<template>
  <div>
    <div class="main-container">
      <main role="main" style="overflow: hidden">
        <BannerArtigo imgBack="imp-social-artigo" />

        <div class="section section-white section-top">
          <div class="container">
            <div class="row">
              <!-- começa o primeiro parágrafo deveria ser title outline-->
              <div class="col-md-6">
                <h1 class="title1">
                  <i style="float: left" :class="imgIcon"></i
                  ><span class="span-relevance-artigo">{{ relevance }}</span>
                  <span>
                    <b style="color: var(--cor-primaria)">{{ title }}</b>
                    {{ subtitle }}</span
                  >
                </h1>
                <span>
                  <b style="color: var(--cor-primaria)">Palavras-chave: </b
                  ><span>{{ keywords }}</span>
                </span>
                <p class="paragraph">
                  <span>{{ author }} {{ datePub }} <br />{{ timePub }}</span>
                </p>
                <div class="paragraph">
                  <p>{{ desc }}</p>
                </div>
              </div>

              <!-- relacionados -->
              <div class="col-md-6">
                <!-- <CardNews :research="research" /> -->
                <Newsletter :hasResearch="false" :research="research" />
                <div id="fixed-roll">
                  <div class="slick-slider slick-cloned">
                    <h2 class="artigo-h2">
                      <span class="title1 title-artigo">
                        Artigos relacionados
                      </span>
                    </h2>
                    <div class="row">
                      <div
                        class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                        v-for="card in cards"
                        :key="card.route"
                      >
                        <CardRelacionado
                          :title="card.title"
                          :subtitle="card.subtitle"
                          :keywords="card.keywords"
                          :relevance="card.relevance"
                          :imgIcon="card.imgIcon"
                          :route="card.route"
                        />
                      </div>
                    </div>
                    <!--   </div> -->

                    <!-- <div class="slick-slider slick-cloned "> -->
                    <h2 class="artigo-h2 artigo-latest-h2">
                      <span class="title1 title-artigo title-artigo-center">
                        últimos artigos
                      </span>
                    </h2>
                    <div class="row">
                      <div
                        class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                        v-for="(card, index) in latest"
                        :key="index"
                      >
                        <CardRelacionado
                          :title="card.title"
                          :subtitle="card.subtitle"
                          :keywords="card.keywords"
                          :relevance="card.relevance"
                          :imgIcon="card.imgIcon"
                          :route="card.route"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- termina relacionados -->
            </div>
          </div>
          <!-- fim do container top -->
        </div>
        <!-- fim da section top -->

        <!-- começa seção do artigo -->
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <!--col-sm-8 col-md-6-->
                  <div class="info">
                    <h2 id="fix">
                      <strong> Qual o valor para criar um aplicativo? </strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        src="../../assets/images/app-example.png"
                        alt="Imagem de uma tela de celular exibindo um app"
                        class="img-responsive"
                      />
                    </figure>
                    <!-- referencia com duvida 01 -->
                    <p>
                      Um aplicativo de celular simples, com sistema de
                      pagamento, e liberado para ambas as plataformas Android e
                      IoS, consome de 100 a 900 horas para ser desenvolvido. E
                      tem o custo aproximado entre R$ 8 mil e R$ 88 mil.
                    </p>
                    <!-- referencia com duvida sobre mvp -->
                    <p>
                      Aplicativos mais complexos podem custar entre R$ 150 mil e
                      R$ 300 mil. Lembrando que esses valores são os gastos
                      apenas com desenvolvimento da fase inicial do projeto, o
                      que chamamos de MVP - Minimum Viable Product.
                    </p>
                    <p>
                      Um projeto pequeno demora de 3 a 6 meses e grandes
                      projetos de 8 meses a 1 ano e meio. Mesmo em um projeto de
                      150 horas de trabalho, não é possível resolver em um mês,
                      pois as etapas exigem interação de pessoas distintas e
                      isso impacta na sequência contínua do projeto, além das
                      fases de testes e correções.
                    </p>
                    <p>
                      Depois de pronto, o aplicativo ainda terá outros custos:
                      manutenção e evolução iniciando em R$ 2 mil reais por mês.
                      O custo de hospedagem em um servidor inicia em R$ 200
                      mensais. No caso de aplicativos complexos, ou com grande
                      volume de uso e que vão exigir servidores mais potentes, o
                      custo total pode variar entre R$ 1 mil a R$ 12 mil por
                      mês.
                    </p>
                    <p>
                      Se o projeto de APP não der certo, dificilmente encontrará
                      outro fornecedor disponível a continuar o projeto, pois é
                      muito custoso e às vezes inviável trabalhar sobre algo
                      feito da forma errada, ou com tecnologias que não são as
                      escolhidas pelo desenvolvedor. É fundamental sentir
                      confiança total na equipe que estará à frente do projeto
                      antes da contratação.
                    </p>
                    <p>
                      Lembrem-se, um app é um software, não um site, e os
                      desafios de se construir de forma universal são
                      diferentes, iremos abordar ao longo desse artigo a
                      importância da universalidade. E o quanto o valor
                      investido é facilmente retornado.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong
                        >Diferença entre acessibilidade e
                        universalidade:</strong
                      >
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        src="../../assets/images/Acessibilidade e universalidade.png"
                        alt="acessibilidade e universalidade"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      Acessibilidade se refere à possibilidade e condição de
                      alcance para utilização, com segurança e autonomia, de
                      espaços, mobiliários, equipamentos urbanos, edificações,
                      transportes, informação e comunicação, inclusive seus
                      sistemas e tecnologias, bem como de outros serviços e
                      instalações abertos ao público, de uso público ou privados
                      de uso coletivo, tanto na zona urbana como na rural, por
                      pessoa com deficiência ou com mobilidade reduzida.
                    </p>
                    <p>
                      O princípio da universalidade assegura a todas as pessoas
                      o acesso às prestações decorrentes dos serviços, o acesso
                      às comodidades materiais decorrentes de tais prestações.
                      Tal princípio traduz, assim, o dever de universalizar o
                      acesso aos direitos fundamentais sociais concretizados
                      mediante os serviços prestados, manifestando-se como
                      condição de realização dos objetivos fundamentais
                      previstos no seu projeto ou negócio.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!--  <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong
                        >Como medir a universalidade do seu negócio/projeto
                      </strong>
                    </h2>
                    <p>...</p>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>Evolução das tecnologias assistivas </strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        src="../../assets/images/tecnologias assistivas.png"
                        alt="tecnologias assistivas"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      O termo tecnologia assistiva agrupa dispositivos, técnicas
                      e processos que podem prover assistência e reabilitação e
                      melhorar a qualidade de vida de pessoas com deficiência. A
                      tecnologia assistiva promove maior independência,
                      permitindo que as pessoas com deficiência executem tarefas
                      que anteriormente não conseguiam ou tinham grande
                      dificuldade em realizar por meio de melhorias ou de
                      mudanças de métodos de interação com a tecnologia
                      necessária para executar estas tarefas.
                    </p>
                    <p>
                      Os recursos são todo e qualquer item, equipamento ou parte
                      dele, produto ou sistema fabricado em série ou sob medida
                      utilizado para aumentar, manter ou melhorar as capacidades
                      funcionais das pessoas com deficiência. Os serviços são
                      definidos como aqueles que auxiliam diretamente uma pessoa
                      com deficiência a selecionar, comprar ou usar os recursos
                      acima definidos.
                    </p>
                    <p></p>
                    <ul
                      style="
                        list-style: inside;
                        list-style-type: disc;
                        text-align: justify;
                      "
                    >
                      <li>
                        <b>Recursos:</b>Podem variar de uma simples bengala a um
                        complexo sistema computadorizado. Estão incluídos
                        brinquedos e roupas adaptadas, computadores, softwares e
                        hardwares especiais, que contemplam questões de
                        acessibilidade, dispositivos para adequação da postura
                        sentada, recursos para mobilidade manual e elétrica,
                        equipamentos de comunicação alternativa, chaves e
                        acionadores especiais, aparelhos de escuta assistida,
                        auxílios visuais, materiais protéticos e milhares de
                        outros itens confeccionados ou disponíveis
                        comercialmente
                      </li>
                      <li>
                        <b>Serviços:</b> São aqueles prestados profissionalmente
                        à pessoa com deficiência visando selecionar, obter ou
                        usar um instrumento de tecnologia assistiva. Como
                        exemplo, podemos citar avaliações, experimentação e
                        treinamento de novos equipamentos. Os serviços de
                        Tecnologia assistiva são normalmente transdisciplinares
                        envolvendo profissionais de diversas áreas, tais como:
                        Fisioterapia, Terapia ocupacional, Fonoaudiologia,
                        Educação, Psicologia, Enfermagem, Medicina, Engenharia,
                        Arquitetura, Design, Técnicos e profissionais de muitas
                        outras especialidades
                      </li>
                    </ul>

                    <p></p>
                    <p>
                      Tenha uma solução acessível de forma muito rápida e
                      econômica. Através de nossa tecnologia de ponta baseada em
                      algoritmos de Inteligência Artificial, podemos ajudá-lo a
                      tornar seu produto e todo seu conteúdo tecnológico
                      acessível. Entre em contato com
                      <a href="mailto:contato@shinier.com.br"
                        >contato@shinier.com.br</a
                      >, ou WhatsApp
                      <a href="https://wa.me/+5516981354817">+5516981354817</a>
                      (Marcius) para agendar uma consultoria.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <!-- <div class="paragraph"> -->
                    <!--  <div class="info"> -->
                    <h2 class="title-h2 title-outline">
                      <b>Pensar em inclusão dá lucro</b>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        src="../../assets/images/lucro e inclusão.png"
                        alt="lucro e inclusão"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      Numa empresa, o lucro se compara à alimentação de uma
                      pessoa, se não o tiver, a empresa morre, mas esse não deve
                      ser o objetivo simplório de um negócio. Ganhar dinheiro é
                      consequência, não o ponto de partida, na ausência de um
                      plano maior, o dinheiro do salário não compensa o
                      sofrimento da jornada. Não se trata de pregar o
                      conformismo, mas injetar propósito, uma verdadeira
                      vocação.
                    </p>
                    <p>
                      Investir em acessibilidade vai muito além do lado social.
                      É uma estratégia de negócio – e você deveria começar a
                      pensar nela como tal. Com isso em mente, você perceberá
                      que: a) você será capaz de atingir um público maior; b)
                      você terá um belo material para divulgar para a imprensa e
                      c) o seu produto ou serviço se tornará invariavelmente
                      melhor para todos.
                    </p>
                    <p>
                      Para ficar mais claro, vou explicar um pouco melhor cada
                      um dos três pontos:
                    </p>

                    <!-- </div> -->
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <!-- motivo 1 -->
                    <div class="paragraph">
                      <!--  <div class="info"> -->
                      <h3 class="title-h2 title-outline">
                        <b>Motivo 1: diversificar e expandir a sua clientela</b>
                      </h3>
                      <figure
                        class="figure figure-artigo"
                        style="float: left; margin-right: 60px"
                      >
                        <img
                          src="../../assets/images/diversificar e expandir a sua clientela.png"
                          alt="Expandir a clientela"
                          class="img-responsive"
                        />
                      </figure>
                      <p>
                        Se você não está investindo em deixar a sua empresa, o
                        seu produto, o seu serviço ou o seu estabelecimento
                        acessível, saiba que você está ignorando uma parcela do
                        seu público alvo. É impossível você estabelecer um
                        público alvo, seja por corte demográfico ou segmentação
                        de interesses, que não contenha pessoas com deficiência.
                        Isso porque elas representam 24% da população segundo
                        IBGE 2010 - Nota técnica 01/2018, e assim como todo
                        mundo, elas trabalham, têm vontades e desejos, consomem,
                        têm poder de compra. Ou seja, você pode estar deixando
                        de atender a 24% do seu público.
                      </p>
                      <p>
                        Ter um produto ou serviço acessível vai muito além de
                        atender o seu público alvo em sua totalidade. Isso
                        porque as pessoas conversam sobre o que consomem,
                        indicam para os amigos, vão aos estabelecimentos
                        acompanhados, etc. Fidelizar um cliente significa não
                        apenas ganhar um defensor da marca, como também
                        conquistar com ele todo o seu grupo social junto. E,
                        boas notícias: as pessoas com deficiência tendem a se
                        fidelizar às marcas que fazem produtos, serviços e
                        publicidade acessíveis.
                      </p>
                      <!--   </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- motivo 2 e 3 -->
            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <!--  motivo 2 -->
                    <div class="paragraph">
                      <!--  <div class="info"> -->
                      <h3 class="title-h2 title-outline">
                        <b
                          >Motivo 2: investimento em acessibilidade gera mídia
                          espontânea
                        </b>
                      </h3>
                      <p>
                        Aparecer na mídia ainda é uma ótima maneira de divulgar
                        o seu negócio. Mas para isso acontecer hoje em dia,
                        neste mar de empreendimentos inovadores, você realmente
                        precisa se destacar do mercado. Uma das maneiras de
                        fazer isso é investindo em acessibilidade.
                      </p>
                      <p>
                        Imagine a manchete: “Empreendedor aumenta 24% do lucro
                        ao investir em acessibilidade”. Você acha que isso não
                        chamaria a atenção? E você poderia dizer como investir
                        em acessibilidade mudou positivamente a cultura e os
                        processos da empresa.
                      </p>
                      <p>
                        Se você estiver de fato (e corretamente) investindo em
                        acessibilidade, não tem problema nenhum sair por aí
                        falando sobre isso. Você não estará se aproveitando
                        disso para se promover, você na verdade promoverá a
                        acessibilidade no mundo. Ajudará a abrir os olhos das
                        outras empresas do mesmo ramo que o seu (ou de outros
                        também, esperamos) e ainda ganhará uma visibilidade que
                        trará retorno para o seu negócio, seja financeiro ou de
                        reconhecimento da marca.
                      </p>

                      <!--   </div> -->
                    </div>

                    <!-- motivo 3 -->
                    <div class="paragraph">
                      <h3 class="title-h2 title-outline">
                        <b
                          >Motivo 3: acessibilidade não é só para pessoas com
                          deficiência – e ela vai melhorar o que você tem a
                          oferecer
                        </b>
                      </h3>

                      <figure
                        class="figure figure-artigo"
                        style="float: right; margin-left: 60px"
                      >
                        <img
                          src="../../assets/images/qualidade e universalidade.png"
                          alt="qualidade e universalidade"
                          class="img-responsive"
                        />
                      </figure>
                      <p>
                        Como explicamos acima o conceito de universalidade.
                        Criar uma embalagem que pode ser aberta com apenas uma
                        das mãos não atenderá apenas a uma pessoa com
                        deficiência, mas também atenderá aquele que está usando
                        o celular e quer abrir a embalagem, ou alguém que está
                        puxando uma mala de rodinhas pelo aeroporto. Pensando
                        assim, este produto além de ser inclusivo, também seria
                        melhor para todos.
                      </p>
                      <p>
                        Quanto mais maduro fica o seu negócio em relação a
                        universalidade, mais você começa a se aproximar de um
                        cenário onde a acessibilidade deixa de ser uma busca por
                        solução de problemas e passa a fazer parte da sua
                        cultura e das suas práticas. Você não precisará mais se
                        preocupar se está ou não fazendo “o suficiente”, pois
                        estará fazendo algo acessível do início ao final do seu
                        processo.
                      </p>
                      <p>
                        Para auxiliar sua empresa com consultoria e/ou
                        desenvolvimento de tecnologia. Entre em contato com
                        <a href="mailto:contato@shinier.com.br"
                          >contato@shinier.com.br</a
                        >, ou WhatsApp
                        <a href="https://wa.me/+5516981354817"
                          >+5516981354817</a
                        >
                        (Marcius) para agendar uma consultoria.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>Por que contratar uma software-house? </strong>
                    </h2>
                    <p>
                      <b>Mão de obra especializada:</b> Se dentro da sua empresa
                      não tem uma equipe especializada em desenvolvimento, muito
                      menos em desenvolver tecnologia para aplicativos, ao
                      contratar uma software house você conta com uma gama de
                      profissionais capacitados:
                    </p>

                    <p></p>
                    <ul
                      style="
                        list-style: inside;
                        list-style-type: disc;
                        text-align: justify;
                      "
                    >
                      <li>Desenvolvedores Sênior, plenos e junior;</li>
                      <li>Especialização técnica para a solução desejada;</li>
                      <li>Engenheiros de Software.</li>
                    </ul>
                    <p></p>
                    <p>
                      <b>Ausência de vínculo trabalhista:</b> Isto é, toda a
                      parte de contratação e investimento em ferramentas fica
                      por conta da agência, deixando você livre para a
                      administração do seu negócio, um exemplo dos processos que
                      são facilitados são:
                    </p>
                    <p></p>
                    <ul
                      style="
                        list-style: inside;
                        list-style-type: disc;
                        text-align: justify;
                      "
                    >
                      <li>Contrato pelo projeto;</li>
                      <li>Suporte sob demanda;</li>
                      <li>Ausência de processo seletivo;</li>
                      <li>Ausência de encargos.</li>
                    </ul>
                    <p></p>
                    <p>
                      <b>Redução de custos:</b> Parece contraditório, mas o
                      investimento mensal com uma agência é bem menor do que
                      montar uma equipe especializada e que fique à disposição
                      da empresa, isso se dá através de:
                    </p>
                    <p></p>
                    <ul
                      style="
                        list-style: inside;
                        list-style-type: disc;
                        text-align: justify;
                      "
                    >
                      <li>
                        Procedimentos detalhados pela empresa de
                        desenvolvimento;
                      </li>
                      <li>
                        Equipe atualizada com o que há de mais novo de solução
                        tecnológica;
                      </li>
                      <li>Validação e Garantia da solução entregue;</li>
                      <li>
                        Contrato fechado pelos requisitos levantados ou
                        proporcional às horas trabalhadas.
                      </li>
                    </ul>
                    <p>
                      Para auxiliar sua empresa com consultoria e
                      desenvolvimento de aplicativos. Entre em contato com
                      <a href="mailto:contato@shinier.com.br"
                        >contato@shinier.com.br</a
                      >, ou WhatsApp
                      <a href="https://wa.me/+5516981354817">+5516981354817</a>
                      (Marcius) para agendar uma consultoria.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção do artigo -->
        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>

        <!-- referencias -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info" id="refs">
                  <h2 class="title1" style="text-align: justify">
                    Referências
                  </h2>
                  <p></p>
                  <ul
                    class="references list-top"
                    style="text-align: justify; list-style: inside"
                  >
                    <li>
                      BRASIL. Decreto 6.949, de 25 de Agosto de 2009. Convenção
                      sobre os Direitos das Pessoas com Deficiência e seu
                      Protocolo Facultativo. Disponível em:
                      http://www.planalto.gov.br/ccivil_03/_ato2007-2010/20099decreto/d6949.htm
                      Acesso em: 01 de Novembro de 2021
                    </li>
                    <li>
                      Censo Demográfico 2010 - Nota técnica 01/2018 - Releitura
                      dos dados de pessoas com deficiência no Censo Demográfico
                      2010 à luz das recomendações do Grupo de Washington
                    </li>
                  </ul>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- fim das referências -->
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import BannerArtigo from "../../components/Blog/BannerArtigo";
import CardRelacionado from "../../components/Blog/CardRelacionado";
/* import CardNews from "../../components/Blog/CardNews"; */
import Newsletter from "../../components/Blog/Newsletter/index.vue";
import showCards from "@/mixins/showCards";
import scrollToTop from "@/mixins/scrollToTop";
import Scroll from "../../mixins/transformToScroll";
export default {
  name: "Artigo01",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Os desafios de criar um aplicativo universal",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content: "Os desafios de criar um aplicativo universal | Shinier"
      },
      {
        name: "description",
        content:
          "No senso comum, a universalidade parece evidenciar os aspectos referentes ao uso e acesso dos espaços físicos. Entretanto, numa acepção mais ampla, é a condição de possibilidade para a transposição dos entraves que representam as barreiras para a efetiva participação de pessoas nos vários âmbitos da vida social."
      },
      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "Os desafios de criar um aplicativo universal | Shinier"
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/aceleracao/os-desafios-de-criar-um-aplicativo-universal"
      },
      {
        property: "og:description",
        content:
          "No senso comum, a universalidade parece evidenciar os aspectos referentes ao uso e acesso dos espaços físicos. Entretanto, numa acepção mais ampla, é a condição de possibilidade para a transposição dos entraves que representam as barreiras para a efetiva participação de pessoas nos vários âmbitos da vida social."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/imp-social.416db4b7.png"
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/imp-social.416db4b7.png"
      },
      {
        name: "twitter:description",
        content:
          "No senso comum, a universalidade parece evidenciar os aspectos referentes ao uso e acesso dos espaços físicos. Entretanto, numa acepção mais ampla, é a condição de possibilidade para a transposição dos entraves que representam as barreiras para a efetiva participação de pessoas nos vários âmbitos da vida social."
      },
      { property: "og:site_name", content: "Shinier | Aceleração" }
    ]
  },
  mixins: [showCards, scrollToTop, Scroll],
  components: {
    BannerArtigo: BannerArtigo,
    CardRelacionado: CardRelacionado,
    /*   CardNews: CardNews, */
    Newsletter: Newsletter,
    GetFeedback: GetFeedback
  }
};
</script>
<style>
@import url("../Aceleracao/index.css");
</style>
