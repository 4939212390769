<template>
  <div>
    <div class="main-container">
      <FiltroHeader />
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : 'overflow: auto'"
      >
        <BannerAbout
          icon3="icon-shinier-color-48 icon-artigo"
          imgBack="artigos"
        />
        <section
          class="list list-new-destaques segmentos"
          style="margin-bottom: 0"
        >
          <div class="container" style="width: 100%">
            <h2 class="title1 title-solucao">
              <span
                class="shadow-span"
                style="background-color: var(--cor-cinza-claro)"
              >
                NOSSA TESE
              </span>
            </h2>
            <p style="text-align: center; color: #6e6e6e; font-size: 16px">
              A tese da
              <span class="notranslate span-notranslate">SHINIER</span>
              baseia-se em classificar em

              <b style="color: #f85025; font-size: 16px">R</b>
              <b style="color: #00ccb3; font-size: 16px">G</b>
              <b style="color: #5696e1; font-size: 16px">B</b>

              as métricas analisadas de cada case, assim conseguimos visualizar
              os pontos a serem melhorados, observando três setores. São eles:
            </p>

            <div
              class="
                slick-destaques slick-initialized slick-slider
                slide-artigo
              "
            >
              <div aria-live="polite" class="slick-list draggable">
                <div
                  class="row slick-track"
                  style="opacity: 1; display: flex; flex-wrap: wrap"
                >
                  <CardImpacto
                    titleImage="gestao-image2 imp-economico"
                    title="Impacto econômico (red)"
                    colMd="4"
                    icon="icon icon-economico"
                    :desc="impacto[1].economico"
                    route=""
                    mais="false"
                  />

                  <CardImpacto
                    titleImage="gestao-image2 imp-ambiental"
                    title="Impacto ambiental (green)"
                    colMd="4"
                    icon="icon icon-ambiental"
                    :desc="impacto[0].ambiental"
                    route=""
                    mais="false"
                  />

                  <CardImpacto
                    titleImage="gestao-image2 imp-social"
                    title="Impacto social (blue)"
                    colMd="4"
                    icon="icon icon-social"
                    :desc="impacto[2].social"
                    route=""
                    mais="false"
                  />
                </div>
              </div>
            </div>
            <div
              class="
                slick-destaques slick-initialized slick-slider
                slide-artigo
              "
              style="margin-bottom: 2%"
            >
              <h2
                data-v-1d9b105c=""
                class="title2 text-uppercase"
                style="
                  color: rgb(87, 130, 173);
                  font-size: 30px;
                  font-weight: 700;
                  text-align: center;
                "
              >
                Procurando aceleração de tecnologia?
              </h2>
              <p style="text-align: center; color: #6e6e6e; font-size: 18px">
                A Shinier é seu braço de tecnologia até o momento de
                internalização do time, temos módulos plug & play, e vamos
                combiná-los sob medida para você. Trabalhamos com valores
                competitivos, e temos uma vantagem para quem já tem cliente e
                está de acordo com nossa tese, trabalhamos com um "desconto" de
                até 50% no valor do projeto, em troca de equity ~5%.
              </p>
              <a
                @click="choosePlan('aceleracao')"
                class="btn btn-orange-solid"
                style="filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                >Desejo uma aceleração</a
              >
            </div>
          </div>
        </section>
        <section class="list list-new-destaques solucoes">
          <div class="container" style="width: 100%">
            <h2 class="title1 title-shadow-span">
              <span class="shadow-span">Últimas postagens</span>
              <p style="font-size: 16px">
                Todos os artigos fazem a análise e explicação da métrica,
                correlacionando de maneira interdiciplinar com cases reais,
                também ao final de cada artigo deixamos prontas todas as
                referências bibliográficas usadas na construção.
              </p>
              <p style="color: blue; font-size: 16px">
                Não deixe de nos auxiliar respondendo os formulários e pesquisas
                que surgem nos pop-ups ao fim de cada artigo.
              </p>
              <p style="color: red; font-size: 16px">
                E o mais importante assine nossa newslatter e fique por dentro
                assim que sair um conteúdo novo!!!
              </p>
            </h2>
            <button
              class="btn-news-geral btn-orange-solid"
              @click="subscribeNews()"
            >
              <span>ASSINAR <em>NEWSLETTER</em> </span>
            </button>
          </div>
          <div class="slick-destaques slick-initialized slick-slider">
            <div
              class="all-soluctions container"
              v-for="card in getCards"
              :key="card.route"
            >
              <CardArtigo
                v-if="card.id != undefined"
                :title="card.title"
                :subtitle="card.subtitle"
                :route="card.route"
                :imgIcon="card.imgIcon"
                :relevance="card.relevance"
                :imgCard="card.imgCard"
                :imgAlt="card.imgAlt"
                :desc="card.desc"
                :author="card.author"
                :datePub="card.datePub"
                :timePub="card.timePub"
                :keywords="card.keywords"
              />
              <div v-if="card.id == undefined">
                <h1 class="title1 results-text">
                  Não foram encontrados resultados.
                </h1>
              </div>
            </div>
          </div>
        </section>

        <!--  -->
      </main>
    </div>
  </div>
</template>
<script>
import BannerAbout from "../../components/Banner/BannerAbout";
import CardImpacto from "../../components/Cards/CardImpacto";
import FiltroHeader from "../../components/Blog/FiltroHeader";
import CardArtigo from "../../components/Blog/CardArtigo";
import subscribeNews from "../../mixins/subscribeNews";
import scrollToTop from "../../mixins/scrollToTop";
import choosePlan from "../../mixins/choosePlan";
export default {
  name: "Artigos",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Aceleração | Todos os artigos da Shinier",
    meta: [
      {
        name: "description",
        content:
          "A Shinier é seu braço de tecnologia. Temos uma vantagem: trabalhamos com 'desconto' de até 50% no valor do projeto, em troca de equity 3% á 15%."
      },

      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content: "Aceleração | Todos os artigos da Shinier"
      },
      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "Aceleração | Todos os artigos da Shinier"
      },
      {
        property: "og:url",
        content: "https://shinier.com.br/aceleracao/"
      },
      {
        property: "og:description",
        content:
          "A Shinier é seu braço de tecnologia. Temos uma vantagem: trabalhamos com 'desconto' de até 50% no valor do projeto, em troca de equity 3% á 15%."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/execution.d6d09d81.svg"
      },
      { property: "og:image:width", content: "400" },
      { property: "og:image:height", content: "400" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/execution.d6d09d81.svg"
      },
      {
        name: "twitter:description",
        content:
          "A Shinier é seu braço de tecnologia. Temos uma vantagem: trabalhamos com 'desconto' de até 50% no valor do projeto, em troca de equity 3% á 15%."
      },
      {
        property: "og:site_name",
        content: "Aceleração | Todos os artigos da Shinier"
      }
    ]
  },
  components: {
    BannerAbout,
    FiltroHeader,
    CardImpacto,
    CardArtigo
  },
  mixins: [subscribeNews, scrollToTop, choosePlan],
  data() {
    return {
      cards: this.$store.state.cards.cards,
      impacto: this.$store.getters.impacto.impacto
    };
  },
  computed: {
    getCards() {
      return !this.$store.getters.cards.length
        ? this.cards
        : this.$store.getters.cards;
    }
  }
};
</script>
<style>
@import url("./index.css");
@import url("../../components/Blog/CardArtigo/index.css");
</style>
