<template>
  <div>
    <div class="main-container">
      <main role="main" style="overflow: hidden">
        <BannerArtigo imgBack="imp-economico-artigo" />

        <div class="section section-white section-top">
          <div class="container">
            <div class="row">
              <!-- começa o primeiro parágrafo deveria ser title outline-->
              <div class="col-md-6">
                <h1 class="title1">
                  <i style="float: left" :class="imgIcon"></i
                  ><span class="span-relevance-artigo">{{ relevance }}</span>
                  <span>
                    <b style="color: var(--cor-primaria)">{{ title }}</b>
                    {{ subtitle }}</span
                  >
                </h1>
                <span>
                  <b style="color: var(--cor-primaria)">Palavras-chave: </b
                  ><span>{{ keywords }}</span>
                </span>
                <p class="paragraph">
                  <span>{{ author }} {{ datePub }} <br />{{ timePub }}</span>
                </p>
                <div class="paragraph">
                  <p>{{ desc }}</p>
                </div>
              </div>

              <!-- relacionados -->
              <div class="col-md-6">
                <!-- <CardNews :research="research" /> -->
                <Newsletter :hasResearch="true" :research="research" />
                <div id="fixed-roll">
                  <div class="slick-slider slick-cloned">
                    <h2 class="artigo-h2">
                      <span class="title1 title-artigo">
                        Artigos relacionados
                      </span>
                    </h2>
                    <div class="row">
                      <div
                        class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                        v-for="(card, index) in cards"
                        :key="index"
                      >
                        <CardRelacionado
                          :title="card.title"
                          :subtitle="card.subtitle"
                          :keywords="card.keywords"
                          :relevance="card.relevance"
                          :imgIcon="card.imgIcon"
                          :route="card.route"
                        />
                      </div>
                    </div>
                    <!--   </div> -->

                    <!-- <div class="slick-slider slick-cloned "> -->
                    <h2 class="artigo-h2 artigo-latest-h2">
                      <span class="title1 title-artigo title-artigo-center">
                        últimos artigos
                      </span>
                    </h2>
                    <div class="row">
                      <div
                        class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                        v-for="(card, index) in latest"
                        :key="index"
                      >
                        <CardRelacionado
                          :title="card.title"
                          :subtitle="card.subtitle"
                          :keywords="card.keywords"
                          :relevance="card.relevance"
                          :imgIcon="card.imgIcon"
                          :route="card.route"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- termina relacionados -->
            </div>
          </div>
          <!-- fim do container top -->
        </div>
        <!-- fim da section top -->

        <!-- começa seção do artigo -->
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <!--col-sm-8 col-md-6-->
                  <div class="info">
                    <h2 id="fix">
                      <strong> O que é ser escalável?</strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        src="../../assets/images/grafico.png"
                        alt="Imagem de um gráfico com uma caneta em cima"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      A resposta curta é minimizar recursos e maximizar
                      resultados, simples assim. Mas vamos abrir um pouco mais
                      os horizontes a seguir.
                    </p>
                    <p>
                      A Escalabilidade é um termo importante quando se trata de
                      negócios, sobretudo no caso de empresas em fase inicial,
                      franquias, StartUps e segmentos inovadores a
                      Escalabilidade é primordial e determinante para o sucesso
                      ou fracasso da empresa, pois dependem fundamentalmente
                      desse fator para conseguir investimentos, eficiência
                      produtiva e alcançarem alta rentabilidade e um rápido
                      retorno financeiro. Compreender o nível de escalabilidade
                      de um negócio é necessário para saber qual o real
                      potencial junto ao mercado, formular o planejamento
                      estratégico e também para compreender as possíveis falhas
                      no seu modelo de negócios.
                    </p>
                    <p>
                      Paradoxalmente, apesar da relevância do termo, o estudo
                      desse tema é pouco explorado no cenário mercadológico e
                      acadêmico no Brasil. Embora muitas vezes seja erroneamente
                      tratada como algo apenas relacionado ao número de
                      clientes, ela possui diversos aspectos e funções no
                      decorrer das diferentes perspectivas e momentos da
                      empresa. Novos projetos, processos, prospecções
                      tecnológicas, decisões estratégicas e muitos outros
                      fatores englobam a Escalabilidade.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>Ciclo de vida de um negócio</strong>
                    </h2>
                    <figure class="figure figure-artigo figure-ciclo">
                      <!--  style="float: right; margin-left: 60px;" -->
                      <img
                        id="crescimento"
                        src="../../assets/images/ciclo-vida-organizacao.png"
                        alt="Gráfico reprsentando o ciclo de vida de uma organização"
                        class="img-responsive"
                      />
                      <figcaption
                        style="font-size: 9px; text-align: center; margin: auto"
                      >
                        Fonte: ADIZES, Ichak.(1990) OS CICLOS DE VIDA DAS
                        ORGANIZAÇÕES
                      </figcaption>
                    </figure>
                    <p>
                      ADIZES (1990), pesquisador sobre crescimento de empresas,
                      compara a evolução de uma empresa ao ciclo de vida
                      semelhante de um ser vivo e para cada uma dessas fases,
                      nitidamente, a Escalabilidade têm sua importância. Desde o
                      princípio, no desenvolvimento do modelo de negócios ao
                      planejamento de estratégias de curto, médio e longo prazo,
                      deve-se levar em consideração a Escalabilidade.
                    </p>
                    <p>
                      Ele defende que o conceito do ciclo de vida aplicado aos
                      organismos vivos também pode ser utilizado para entender o
                      desenvolvimento organizacional. Como um ser vivo há o
                      nascimento, o crescimento, cujo fim se dá com o
                      envelhecimento e declínio, a extinção ou a morte da
                      organização. Aponta também as dificuldades em cada etapa
                      do ciclo de vida, principalmente, quando ocorrem as
                      mudanças de estágios. Os fatores flexibilidade e controle
                      são constantemente alterados no decorrer do ciclo.
                    </p>
                    <p>
                      Os diversos estágios de crescimento são apresentados nesta
                      sequência: Namoro, Infância, Pré-Adolescência (Toca-Toca),
                      Adolescência, Plenitude, Estabilidade, Aristocracia, Pré-
                      Burocracia, Burocracia e Morte. O gráfico ao lado ilustra
                      o ciclo de vida.
                    </p>

                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong
                        >Como medir a escalabilidade da sua empresa</strong
                      >
                    </h2>
                    <p>
                      Bom, mas toda métrica deve ser mensurada, qualitativamente
                      ou quantitativamente, como medir então a escalabilidade?
                      Quando pensamos em uma empresa grande e de sucesso, logo
                      pensamos em um grande espaço, com muitos funcionários e
                      uma infraestrutura robusta, mas isso quer dizer que essa
                      empresa é escalar?
                    </p>
                    <p>
                      Vamos pensar no exemplo de uma empreiteira, com muitos
                      pedreiros, ajudantes e engenheiros, com 100 colaboradores
                      é possível construir um edifício, e se ela quiser reduzir
                      esse tempo, deverá colocar mais pessoas. Porém se essa
                      mesma empresa investir em um maquinário de construção
                      modular, logo a mesma máquina fará o serviço de 100, mas
                      se a empresa quiser construir mais prédios ela terá que
                      contratar mais máquinas.
                    </p>
                    <p>
                      Com esse exemplo simples percebemos que podemos medir a
                      escalabilidade com 3 fatores:
                    </p>
                    <p>
                      <b>Capacidade de produção:</b> no nosso exemplo quanto
                      mais prédios a empresa é capaz de construir;
                    </p>
                    <p>
                      <b>Quantidade de recursos utilizados:</b> quantidade de
                      pessoas ou máquinas necessárias para construir um prédio;
                    </p>
                    <p>
                      <b>Tempo:</b> comparação temporal da capacidade e
                      quantidade.
                    </p>
                    <p>
                      Logo a escalabilidade pode ser descrita como a taxa de
                      mudança na capacidade de produção em relação aos recursos
                      utilizados ao longo do tempo:
                    </p>
                    <figure class="figure figure-artigo figure-ciclo">
                      <!--  style="float: right; margin-left: 60px;" -->
                      <img
                        id="equacao"
                        src="../../assets/images/formula-escalabilidade.png"
                        alt="Fórmula da escalabilidade"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      A escalabilidade é uma medida observada dentro da
                      organização ao longo do tempo como podemos observar no
                      gráfico “OS CICLOS DE VIDA DAS ORGANIZAÇÕES”, as empresas
                      começam com um crescimento “lento”, e conforme o passar do
                      tempo ganha velocidade, até atingirem um pico, e depois
                      começam a diminuir até deixarem de existir.
                    </p>
                    <p>
                      Algumas empresas assumem um rápido crescimento, propondo
                      uma disrupção, porém em algum momento deverão pivotar seu
                      negócio para ter um período de pico de estabilidade
                      grande, ou ter sucessivos períodos de estabilidade e
                      aceleração, veja a seguir como escalar seu negócio.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong> 4 passos para escalar seu negócio</strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        src="../../assets/images/quartz-1.png"
                        alt="Equipe de Trabalho"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      <b>Operacional:</b> o negócio depende do seu braço para
                      funcionar. É um primeiro passo importante para quem que
                      empreender, muito conciliam essa fase com mais de um
                      emprego, é normal ser você que realiza o produto ou
                      serviço, quem faz as vendas, o financeiro e todas as
                      encubencias do negócio. Porém nessa fase se você ficar
                      doente o negócio simplesmente para, e é a partir dessa
                      experiência que muitos dão o próximo passo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <figure
                      class="figure figure-artigo"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        src="../../assets/images/business.png"
                        alt="Negócios"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      <b> Executivo:</b> é contratar um outro operacional para
                      te ajudar em funções que você não tem domínio, esta fase
                      geralmente é a de maior risco, pois é quando muitos
                      empreendedores recorrem a empréstimos, para ter um folga
                      na flutuação do mercado, e garantir o pagamento dos
                      funcionários. Mas o negócio de certa forma ainda depende
                      de você, afinal seu dia só tem 24 horas. E como fazer para
                      poder escalar mais?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <figure
                      class="figure figure-artigo"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        src="../../assets/images/estrategico.png"
                        alt="Duas peças de xadrez em cima de um tabuleiro, 
                        representando os passos estratégicos para o crescimento de um negócio"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      <b> Estratégico:</b> Deixa de ser executivo e vira alguém
                      estratégico da empresa, então você começa a contratar
                      executivos, que vão contratar os operacionais e você vai
                      evoluindo cada vez mais. Uma grande sacada é: que por mais
                      distante que seja seu cargo do operacional, você nunca
                      deve deixar de ser alguém que tem um pezinho pelo menos
                      operacional. Porque você começa a ficar tão distante do
                      seu cliente, e no final você perde um pouco do Tino.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <figure
                      class="figure figure-artigo"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        src="../../assets/images/crescimento.png"
                        alt="Blocos agrupados com setas para cima desenhadas, representando crescimento"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      <b> Escalada:</b> Bom, agora você chegou no tão esperado
                      sonho de muitos empreendedores, você tem uma equipe
                      grande, seu produto ou serviço é vendido, gestionado e
                      produzido mesmo que você não interfira em nada. Porém
                      escalar não é simples, não basta apenas acumular lucro e
                      reservar para investir mais no negócio, muito menos
                      procurar um investidor. Isso porque muitos negócios não
                      são escalados no atual modelo, é necessário pivotar: criar
                      uma cultura de startup, se tornar uma plataforma; montar
                      uma franqueadora; ou virar um marketplace.
                    </p>
                    <p>
                      Ao fazer isso seu negócio em vez de sofrer com o declínio
                      de novos entrantes, e defasagem, você estará sempre se
                      renovando. Veja como a Shinier pode te ajudar nos tópicos
                      a seguir.
                    </p>
                    <br /><br />
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>O que é uma startup?</strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        src="../../assets/images/startup.png"
                        alt="Startup e ideias escritas em um vidro, representando a construção de um plano de negócios"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      O termo startup, do inglês, significa “começar algo novo”
                      e é comumente relacionado à inovação no mundo dos
                      negócios. Portanto, uma startup é uma empresa que está em
                      seu início, com um plano de negócios enxuto, e geralmente
                      com produto/serviço em construção, mas que tem algo novo a
                      mostrar ao mercado, o que chamamos de disrupção.
                    </p>
                    <p>
                      A palavra “disrupção” significa a interrupção do curso
                      natural de algum processo. A proposta da inovação
                      disruptiva é a de simplificação, transformando aquilo que
                      é complexo em algo mais acessível. Um bom exemplo em nosso
                      cotidiano são os streamings de música, vídeo e etc.
                    </p>

                    <div class="paragraph">
                      <!--  <div class="info"> -->
                      <h3 class="title-h2 title-outline">
                        <b>Quando uma startup deixa de ser uma startup?</b>
                      </h3>
                      <p>
                        A partir do momento em que o seu modelo de negócio se
                        torna escalável e sustentável, ou seja, quando a empresa
                        deixa de ser uma startup e passa a ser uma empresa
                        altamente lucrativa.
                      </p>
                      <!-- </div> -->
                    </div>

                    <div class="paragraph">
                      <!--  <div class="info"> -->
                      <h3 class="title-h2 title-outline">
                        <b>Como abrir uma startup?</b>
                      </h3>

                      <p></p>
                      <ul
                        style="
                          list-style: inside;
                          list-style-type: disc;
                          text-align: justify;
                        "
                      >
                        <li>
                          Desenvolva a sua ideia e elabore um modelo de negócios
                        </li>
                        <li>Busque informações e troque ideias</li>
                        <li>Qualifique-se</li>
                        <li>Valide a sua ideia</li>
                        <li>Escolha os sócios e tenha tudo em contrato</li>
                        <li>Formalize-se</li>
                        <li>Conte com a ajuda de especialistas</li>
                        <li>Escolha um bom endereço comercial</li>
                        <li>Não erre na contratação da equipe</li>
                        <li>Tenha um bom planejamento</li>
                      </ul>

                      <p></p>

                      <p>
                        Imagino que se você entrou buscando essa pergunta o que
                        mais te interessa é o tópico “VI Formalize-se”: Bom se
                        seu modelo de negócio e totalmente tecnológico aqui no
                        Brasil a forma correta seria abrindo uma pequena
                        empresa, na modalidade de Lucro Presumido, uma vez que é
                        comum as startups não terem lucro no começo, então nada
                        de imposto até você ter fluxo de caixa, mas isso teria
                        os encargos de ter um local (mesmo que um ponto
                        alugado), ter um contato, ou contratar um serviço
                        online, entre outras burocracias que dificultariam seu
                        mês a mês.
                      </p>
                      <p>
                        Porém existem algumas liberdades para você ter um CNPJ
                        para começar seu trabalho até que tenha pelo menos o
                        fluxo suficiente para dar este passo. E é abrir uma MEI
                        que se enquadre no setor que você está operando.
                        <b>Por exemplo:</b> se você está desenvolvendo uma
                        plataforma para faxineiras, pode pegar os CNAE do MEI
                        desse setor para começar a operar. Isso permitirá você a
                        emitir notas de serviço, abrir contas em bancos, usar
                        planos de serviço de terceiro no CNPJ e etc, e quando
                        for abrir a empresa basta apenas pedir a migração do
                        CNPJ, para o contador
                      </p>
                      <p>
                        Quer ajuda para montar seu passo a passo entre em
                        contato com
                        <a
                          href="mailto:acelerarstartup@shinier.com.br"
                          class="notranslate"
                          >acelerarstartup@shinier.com.br</a
                        >
                        , ou WhatsApp
                        <a href="https://wa.me/+5516981354817"
                          >+5516981354817</a
                        >
                        (Marcius)
                      </p>
                      <!--   </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <br />
                    <h2 class="title-h2">
                      <strong>Como criar uma plataforma?</strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        src="../../assets/images/plataform.png"
                        alt="Pessoa sentada em frente ao notebook"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      Temos a tendência de considerar que qualquer aplicativo é
                      uma plataforma, mas a verdade é que ser uma plataforma
                      envolve mais do que ser apenas um software. Isso porque,
                      quando o seu produto ou serviço se transforma em
                      plataforma, significa que ele vai deixar de ser apenas uma
                      solução padronizada para um determinado problema, para se
                      tornar um ambiente de conexão entre usuários e prestadores
                      de serviços.
                    </p>
                    <p>
                      Os exemplos mais “clássicos” de plataformas são o
                      Facebook, Tinder, Airbnb e Amazon, mas também podemos
                      citar os ótimos cases nacionais como o Small Doações, que
                      conecta ONGs que têm necessidade de captação e
                      estruturação financeira, e entrega um serviço de
                      consultoria e conecta a pessoas para fazerem doações. E o
                      Market Brooker, que conecta corretores às pessoas que
                      querem pôr para alugar/vender a pessoas que querem
                      alugar/comprar uma casa, terreno ou apartamento. – ambas
                      desenvolvidas pela Shinier.
                    </p>
                    <p>
                      Ao contrário dos produtos, as plataformas conectam os
                      usuários e fornecem as ferramentas necessárias para que
                      eles encontrem a melhor solução para seus problemas. Em
                      outras palavras, uma plataforma nada mais é do que o
                      conjunto de ferramentas que permite a troca de valor entre
                      os usuários.
                    </p>

                    <div class="paragraph">
                      <!--  <div class="info"> -->
                      <h3 class="title-h2 title-outline">
                        <b>
                          Passo a passo: como transformar seu serviço ou produto
                          em uma plataforma?</b
                        >
                      </h3>

                      <p></p>
                      <ul
                        style="
                          list-style: inside;
                          list-style-type: disc;
                          text-align: justify;
                        "
                      >
                        <li>
                          <b>Comece com um bom produto ou serviço:</b>
                          Não tente utilizar um produto ou serviço desgastado,
                          uma vez que transformá-lo em plataforma não irá
                          “salvá-lo”, se ele não agrega nenhum valor ao seu
                          público-alvo.
                        </li>
                        <li>
                          <b>Defina as funcionalidades da versão inicial: </b>
                          Foque em criar uma
                          <a
                            style="
                              font-weight: 700;
                              color: var(--cor-principal);
                            "
                            @click="$router.push({ name: 'Duvida08' })"
                          >
                            MVP (minimum viable product).</a
                          >
                          selecione apenas as funcionalidades fundamentais para
                          o funcionamento da plataforma e, se for o caso, que
                          diferenciam a sua solução dos concorrentes.
                        </li>
                        <li>
                          <b>Escolha quem vai desenvolver:</b> A escolha da
                          empresa desenvolvedora é provavelmente a mais
                          importante de todo o processo de transformação de
                          serviços e produtos em plataformas.
                        </li>
                      </ul>
                      <p></p>

                      <p>
                        Afinal, você não quer colocar “nas mãos” de qualquer
                        pessoa toda a reputação, base de usuários e tecnologia
                        que sua empresa já conseguiu agregar até o momento. Por
                        isso, esqueça os freelancers. Apesar de serem as opções
                        mais baratas, o retrabalho e a ausência de
                        comprometimento podem custar caro no médio e longo
                        prazo.
                      </p>
                      <p>
                        Contratar um desenvolvedor interno também poderia ser
                        uma opção viável mas, ao contrário do que muitas pessoas
                        imaginam, o desenvolvimento de uma plataforma vai muito
                        além das linhas de códigos. É necessário focar na
                        experiência do usuário (UX), design e dezenas de outros
                        detalhes que apenas uma equipe multidisciplinar pode
                        resolver.
                      </p>
                      <p>
                        A Shinier é uma empresa especializada na consultoria, no
                        desenvolvimento de aplicativos e plataformas. temos um
                        portfólio de clientes nacionais e internacionais. Iremos
                        agregar valor a sua empresa e acompanhar de perto o
                        crescimento, passo-a-passo, entre em contato conosco
                        hoje mesmo por
                        <a
                          href="mailto:plataforma@shinier.com.br"
                          class="notranslate"
                          >plataforma@shinier.com.br</a
                        >
                        , ou WhatsApp
                        <a href="https://wa.me/+5516981354817">
                          +5516981354817</a
                        >
                        (Marcius)
                      </p>
                      <br />
                      <!--  </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2 class="title-h2">
                      <strong>Como abrir uma franqueadora?</strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        src="../../assets/images/franquia.png"
                        alt="Blocos agrupados repesentando uma franquia de lojas"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      Bom, a franquia é também o modelo de negócio escalável e
                      mais acessível para quem vende produtos, uma vez que, seu
                      produto se prove rentável e tenha aderência de mercado.
                      Formatar os processos da sua empresa e abrir uma segunda
                      loja para validar a viabilidade, é o caminho para empresa
                      de sucesso.
                    </p>
                    <p>
                      Depois disso aí sim se tornar uma franqueadora shinier
                      pode te colocar em contato com consultores financeiros,
                      contadores, marketing especializado e advogados, sem custo
                      algum. Estaremos te auxiliando em todo o processo
                      tecnológico, para dar à sua franquia um diferencial ter um
                      software de apoio à gestão e vendas exclusivo.
                    </p>
                    <p>
                      Mas caso queira saber mais, aqui vão alguns tópico que
                      podemos conversar mais em uma video chamada:
                    </p>
                    <br />
                    <p></p>
                    <ul style="list-style: inside; text-align: justify">
                      <li>
                        Formatar sua franquia (Valuation, Breakeven, Payback, e
                        etc)
                      </li>
                      <li>Conheça a Lei do Franchising (13.966/19.)</li>
                      <li>Planejamento do processo de transição</li>
                      <li>
                        Estabeleça processos e níveis de excelência, apoiados a
                        sistemas
                      </li>
                      <li>Modelos de contratos</li>
                      <li>Desenvolva os processos de formação e suporte</li>
                      <li>Crie uma unidade modelo</li>
                      <li>Elabore o plano de marketing da franquia</li>
                      <li>
                        Documentos de uma franquia
                        <ul
                          style="
                            list-style-type: disc;
                            text-align: justify;
                            margin-left: 5%;
                          "
                        >
                          <li>Circular de Oferta de Franquia (COF)</li>
                          <li>Contrato de franquia</li>
                        </ul>
                      </li>
                    </ul>
                    <p></p>

                    <p>
                      Entre em contato conosco por
                      <a
                        href="mailto:franquia@shinier.com.br"
                        class="notranslate"
                        >franquia@shinier.com.br</a
                      >, ou WhatsApp
                      <a href="https://wa.me/+5516981354817"> +5516981354817</a>
                      (Marcius)
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2 class="title-h2">
                      <strong>Quanto custa fazer um marketplace?</strong>
                    </h2>
                    <figure
                      class="figure figure-artigo"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        src="../../assets/images/online-market-place.png"
                        alt="Mini carrinho de compras com caixas dentro representando loja online"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      Vamos direto ao ponto, nossa experiência e formatação, nos
                      deixa afirmar com clareza, você irá gastar pelo menos R$
                      80.000,00 em software e o pelo menos o dobro disso, em
                      marketing, mais gastos gerais (adminstrativo, sua hora de
                      trabalho, burocracias e etc). sendo assim o investimento
                      para montar um marketplace, vai ser na faixa de 160 mil a
                      240 mil. Porém não desanime.
                    </p>

                    <p>
                      Você pode e deve encontrar parceiros de sucesso, como um
                      sócio para te ajudar inicialmente no marketing e venda,
                      tendo um salário simbólico e que irá crescer junto com a
                      empresa, e terá total dedicação por ter participação nos
                      negócios. Investir em processos simples de administração,
                      para reduzir custo de contabilidade, fornecedores e etc. E
                      é claro contar com as soluções pré-formatadas da Shinier
                      para entregar uma tecnologia de excelência e amplamente
                      testada.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="info">
                  <div class="paragraph">
                    <!-- <div class="info"> -->
                    <h3 class="title-h2 title-outline">
                      <b>
                        Potencial de Faturamento x Custos para Montar um
                        Marketplace
                      </b>
                    </h3>

                    <figure
                      class="figure figure-artigo left"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        src="../../assets/images/gold-3.png"
                        alt="Cesta de supermercado pequena em cima de um teclado de notebook representando loja online"
                        class="img-responsive"
                      />
                    </figure>
                    <p>
                      É claro que com um custo tão alto de investimento, o
                      potencial de faturamento de um marketplace é bem
                      relevante. Empresas de sucesso como Uber, Airbnb, Amazon e
                      Mercado Livre utilizam esse modelo de negócios. Colocando
                      em número a Amazon ganhou US $10,1 bilhões em 2018. Já o
                      Mercado Livre teve lucro bruto de US $204,8 milhões.
                    </p>
                    <p>
                      Porém, não existe nenhum negócio milagroso. Apesar de
                      possibilitar grandes lucros, o modelo de marketplace
                      demora no mínimo um ano, e em média 3 anos para atingir um
                      ponto de equilíbrio. Embora não seja um negócio com
                      retorno a curto prazo, o faturamento compensa a espera.
                    </p>
                    <p>
                      O mais importante para o marketplace trazer retorno é ter
                      uma boa estratégia e um planejamento eficiente. Ou seja,
                      saber quanto você tem para investir mês a mês e quando
                      você irá investir em cada etapa e área do marketplace.
                      Para entender como fazer isso, entre em contato conosco
                      por
                      <a href="mailto:marketplace@shinier.com.br">
                        marketplace@shinier.com.br</a
                      >
                      , ou WhatsApp
                      <a href="https://wa.me/+5516981354817">+5516981354817</a>
                      (Marcius)
                    </p>
                    <!--  </div> -->
                  </div>

                  <div class="paragraph">
                    <!--  <div class="info"> -->

                    <h3 class="title-h2 title-outline">
                      <b> Categorias de Custos de um Marketplace </b>
                    </h3>
                    <p>
                      <b>Contabilidade:</b> O primeiro custo para montar um
                      marketplace é criar um CNPJ. O CNAE marketplace mais
                      recomendado é o 74.90-1-04, referente à intermediação de
                      negócios. Além disso, é importante ter um orçamento
                      separado para a manutenção do CNPJ. Você pode contratar
                      uma contabilidade online, que normalmente é mais
                      acessível. Mas, se você quiser um atendimento mais
                      próximo, pode ser melhor contratar um profissional de
                      contabilidade da sua região.
                    </p>
                    <p>
                      <b> Jurídico:</b>
                      Outro custo para montar um marketplace é o jurídico, esse
                      investimento é mais pontual, para te auxiliar nos modelos
                      de contratos. Outra dica nessa área é prezar pela
                      qualidade dos vendedores ou prestadores de serviço do seu
                      marketplace. Faça uma curadoria nos cadastros iniciais e
                      fique sempre de olho nas avaliações. Isso vai te ajudar a
                      manter seu marketplace confiável.
                    </p>
                    <p>
                      <b> Marketing:</b>
                      O marketing é um investimento fundamental para montar um
                      marketplace. Existem diversas estratégias de marketing
                      para marketplace, tanto online quanto offline. Eventos,
                      parcerias e indicações são alguns exemplos.
                    </p>
                    <p>
                      <b> Pessoas:</b>
                      No começo do seu marketplace, você não precisa
                      necessariamente contratar pessoas. Uma possibilidade é que
                      você mesmo faça a operação. Se você tiver sócios, isso
                      também pode ajudar a distribuir as tarefas.
                    </p>
                    <p id="last-p">
                      <b> Tecnologia:</b>
                      Nos demais tópicos a shinier poderá auxiliá-lo nas
                      melhores práticas e encontrar os melhores profissionais.
                      Mas principalmente contar com a solução
                      <a @click="$router.push({ name: 'Gold' })">Gold</a> da
                      Shinier irá te economizar e dar garantia de estar tomando
                      as melhores decisões, por isso entregamos uma solução com:
                    </p>

                    <p></p>
                    <ul
                      style="
                        list-style: inside;
                        list-style-type: disc;
                        text-align: justify;
                      "
                    >
                      <li>Gateway de Pagamento</li>
                      <li>
                        Servidor Web Seguro (usamos a plataforma da nossa
                        parceira Amazon - AWS)
                      </li>
                      <li>Ferramentas de Marketing</li>
                      <li>Aplicativo</li>
                      <li>Plataforma administrativa web</li>
                      <li>
                        Integração com serviços de transportadoras nacionais e
                        internacionais e muitos outros.
                      </li>
                    </ul>
                    <p></p>

                    <p>
                      Quer criar o seu marketplace? Agende hoje uma consultoria
                      gratuita com a nossa equipe.
                      <a
                        href="mailto:marketplace@shinier.com.br"
                        class="notranslate"
                      >
                        marketplace@shinier.com.br</a
                      >
                      , ou WhatsApp
                      <a href="https://wa.me/+5516981354817">+5516981354817</a>
                      (Marcius)
                    </p>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- termina seção do artigo -->

        <!-- seção feedback -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info"><GetFeedback /></div>
              </div>
            </div>
          </div>
        </div>
        <!-- referencias -->
        <div class="container">
          <div class="block-beneficios">
            <div class="beneficios-wrapper">
              <div class="paragraph">
                <div class="info" id="refs">
                  <h2 class="title1" style="text-align: justify">
                    Referências
                  </h2>
                  <p></p>
                  <ul
                    class="references list-top"
                    style="text-align: justify; list-style: inside"
                  >
                    <li>
                      ADIZES, Ichak. (1990). OS CICLOS DE VIDA DAS ORGANIZAÇÕES:
                      Como e Porque as Empresas Crescem e Morrem e O que Fazer a
                      Respeito, São Paulo: Ed.Pioneira Thomson.
                    </li>
                    <li>
                      BLANK, Steven Gary (2005). The Four Steps to the Epiphany:
                      Successful Strategies for Products that Win
                    </li>
                    <li>
                      BANNOCK, G., R. E. Baxter, and E. Davis. 2003. The Penguin
                      dictionary of economics. Penguin Books Ltd. 7 Rev
                      Ededition. 416 pp
                    </li>
                    <li>
                      CHURCHILL, N.C. & LEWIS, V. (1983). The Five Stages of
                      Small Business Growth. Harvard Business Review.
                    </li>
                    <li>
                      SCHICK, Hildegard; MARXEN, Sandra; FREIMANN, Jürgen.
                      (2002). Sustainability Issues for Start-up Entrepreneurs.
                    </li>
                    <li>
                      SCHUMPETER, J. A. (1984). Capitalismo, Socialismo e
                      Democracia. Rio de Janeiro: Ed. Zahar.
                    </li>
                    <li>
                      SEVERINO, Antônio J. (2000). Metodologia do trabalho
                      científico. Ed. Cortez.
                    </li>
                    <li>
                      SUTTON, R.I. & RAO, H. Scaling Up Excellence – Getting to
                      more without settling for less, 2014.
                    </li>
                    <li>TIGRE, P. (2006). Gestão de Inovação. Ed. Campus.</li>
                    <li>
                      TORRES, Norberto (1995 )A. Competitividade Empresarial com
                      a Tecnologia da Informação. São Paulo. Makron Books
                    </li>
                  </ul>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- fim das referências -->
      </main>
    </div>
  </div>
</template>
<script>
import GetFeedback from "../../components/Feedback/GetFeedback.vue";
import BannerArtigo from "../../components/Blog/BannerArtigo";
import CardRelacionado from "../../components/Blog/CardRelacionado";
/* import CardNews from "../../components/Blog/CardNews"; */
import showCards from "@/mixins/showCards";
import scrollToTop from "@/mixins/scrollToTop";
import Newsletter from "../../components/Blog/Newsletter/index.vue";
import Scroll from "../../mixins/transformToScroll";
export default {
  name: "Artigo01",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Como crescer meu negócio rápido",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content: "Como crescer meu negócio rápido | Shinier"
      },
      {
        name: "description",
        content:
          "A Escalabilidade é um fator determinante para o futuro das empresas em fase inicial e StartUps, as quais dependem desse fator para crescerem."
      },
      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "Como crescer meu negócio rápido | Shinier"
      },
      {
        property: "og:url",
        content:
          "https://shinier.com.br/aceleracao/como-crescer-meu-negocio-rapido"
      },
      {
        property: "og:description",
        content:
          "A Escalabilidade é um fator determinante para o futuro das empresas em fase inicial e StartUps, as quais dependem desse fator para crescerem."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/imp-economico.540afa0e.png"
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/imp-economico.540afa0e.png"
      },
      {
        name: "twitter:description",
        content:
          "A Escalabilidade é um fator determinante para o futuro das empresas em fase inicial e StartUps, as quais dependem desse fator para crescerem."
      },
      { property: "og:site_name", content: "Shinier | Aceleração" }
    ]
  },
  mixins: [showCards, scrollToTop, Scroll],
  components: {
    BannerArtigo: BannerArtigo,
    CardRelacionado: CardRelacionado,
    /* CardNews: CardNews, */
    Newsletter: Newsletter,
    GetFeedback: GetFeedback
  }
};
</script>
<style>
@import url("../Aceleracao/index.css");
</style>
